






import Vue from "vue";

const params = new URLSearchParams(window.location.search);
const token = params.get('token')! || localStorage.getItem('token')!;
const deviceId = localStorage.getItem('device_id')! || "";

export default Vue.extend({
  name: "App",
  created() {
    this.$socket.emit("join", deviceId)
    this.sockets.subscribe("incoming", (data) => {
      if (this.$store.state.chat.currentChat) {
        if (this.$store.state.chat.currentChat.number == data.number) {
          const newMessage = {
            id: data.message_id,
            fromMe: data.fromMe ? true : false,
            body: data.message,
            ack: "2",
            type: data.media ? "media" : "chat",
            timestamp: new Date().getTime(),
          };
          this.$store.commit("UPDATE_CHAT_MESSAGES", newMessage);
        }
        this.$store.dispatch("SET_ACTIVE_CHAT_USERS");
        this.$store.dispatch("SET_PENDING_CHAT_USERS");
      }else{
        this.$store.dispatch("SET_ACTIVE_CHAT_USERS");
        this.$store.dispatch("SET_PENDING_CHAT_USERS");
      }
    });
  },
});
